<template>
  <div id="tables">
    <el-container class="container">
      <el-header>xml文件列表</el-header>
      <el-main>
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="divItem"
          v-html="item"
        />
      </el-main>
      <el-footer>
        <el-button type="primary" @click="adjustment">调整</el-button>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { part } from "@/utils/mixins";
import { myRequest } from "@/request/index";
export default {
  mixins: [part],
  name: "dateweek",
  data() {
    return {
      listData: [],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions("global", ["onExtendClick"]), // 获取数据
    getData() {
      myRequest({
        url: "/dateweek/xml.do",
      }).then((res) => {
        if (res.status === 200) {
          this.listData = res.data.data.filenames;
        }
      });
    },

    // 调整
    adjustment() {
      myRequest({
        url: "/dateweek/xmlsave.do",
        data: this.$qs.stringify(
          {
            cboXml: this.listData,
          },
          { arrayFormat: "repeat" }
        ),
      });
    },
  },
};
</script>

<style>
.container {
  width: 30%;
  margin: 30px 20px;
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  height: 350px;
}

.divItem {
  margin: 5px 0;
  height: 30px;
  line-height: 30px;
}
</style>
